<template>
  <div class="h100">
    <List ref="list" :optionsUrl="'status=active'" />
  </div>
</template>
<script>
import List from "./List";

export default {
  components: { List },
  data() {
    return {};
  },
};
</script>