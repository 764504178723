<template>
  <div class="filters">
    <img src="@/assets/img/filter.svg" alt="" />
    <div
      v-for="filter in useList"
      @click="selectFilter(filter)"
      :class="{ selected: inFilters(filter.key) }"
      :key="filter.key"
    >
      {{ filter.name }}
    </div>
  </div>
</template>

<script>
export default {
  props: { list: Array },
  data() {
    return {
      tab: "order",
      filters: [],
    };
  },
  computed: {
    useList() {
      return this.list.map((e) => {
        return { ...e, key: e.key || e.name };
      });
    },
  },
  watch: {
    filters() {
      let metaJoin = {};
      this.filters.forEach((e) => {
        Object.keys(e.meta).forEach((k) => {
          metaJoin[k]
            ? metaJoin[k].push(e.meta[k])
            : (metaJoin[k] = [e.meta[k]]);
        });
      });
      this.$emit("filters", this.filters);
      this.$emit("metaJoin", metaJoin);
    },
  },
  methods: {
    inFilters(key) {
      return this.filters.some((e) => e.key === key);
    },
    selectFilter(val) {
      if (this.filters.includes(val)) {
        this.filters = this.filters.filter((item) => item !== val);
      } else {
        this.filters.push(val);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.filters {
  padding: 10px 8px;
  overflow-x: scroll;
  // width: 100%;
  display: flex;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  white-space: nowrap;
  z-index: 1;
  cursor: pointer;
  > img {
    margin: 0 5px;
  }

  &::after {
    content: "";
    padding-right: 10px;
  }

  > div {
    color: rgb(131, 131, 131);
    font-size: 12px;
    border: 1px solid rgb(218, 218, 218);
    padding: 5px 7px;
    display: inline-block;
    border-radius: 8px;
    margin: 0 2.5px;
    background: rgba(255, 255, 255, 0.795);
    &.selected {
      color: rgb(112, 112, 112);
      border-color: rgb(218, 218, 218);
      background: rgb(218, 218, 218);
    }
  }
}
</style>