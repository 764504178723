<template>
  <div class="h100 orderList">
    <Filters
      v-if="filtersKey.length > 0"
      @metaJoin="updatefilters"
      :list="outputFiltersList"
    />
    <div class="card_wrap">
      <div
        class="card"
        v-for="(item, index) in list"
        :key="index"
        @click="$router.push('/settings/promotion/id/' + item.id)"
      >
        <div class="name">
          <b>{{ item.name }}</b>
        </div>
        <div class="des">
          เริ่ม {{ item.startTime && toDate(item.startTime) }} ถึง
          {{ item.endTime && toDate(item.endTime) }}
          <div class="activeSection">
            การเปิดใช้งาน:
            <span class="active" v-if="item.active">เปิดใช้งาน</span>
            <span class="inActive" v-if="!item.active">ปิดใช้งาน</span>
          </div>
        </div>
      </div>
    </div>
    <scroll-loader
      :loader-method="() => getData()"
      :loader-disable="loading || endList || error"
      :loader-size="0"
      class="reset"
    >
      <OrderListLoader :error="error" :nodata="nodata" :fetching="loading" />
    </scroll-loader>
    <DefaultDisplayStage :fetching="loading" :error="error" :nodata="nodata" />
    <div v-if="!error && !nodata" style="height: 100px"></div>
  </div>
</template>

<script>
import OrderListLoader from "@/components/loader/OrderListLoader";
import DefaultDisplayStage from "@/components/DefaultDisplayStage";
import Filters from "@/views/components/Filters";
import { statusKey } from "@/utils/mapOrderStatus";
import getTextKey from "@/utils/getTextKey";
import { GET } from "@/utils/http";

export default {
  props: {
    filters: Object,
    filtersKey: { type: Array, default: () => [] },
    optionsUrl: String,
  },
  components: { OrderListLoader, DefaultDisplayStage, Filters },
  data() {
    return {
      list: [],
      error: false,
      loading: false,
      page: 1,
      endList: false,
      outputFilters: this.filters,
      allfiltersOrder: [],
    };
  },
  created() {
    // prepare all config filters of order
    this.allfiltersOrder = [
      {
        key: "payment_pending",
        meta: { paymentStatus: "pending" },
      },
      {
        key: "payment_inform",
        meta: { paymentStatus: "inform" },
      },
      {
        key: "payment_paid",
        meta: { paymentStatus: "paid" },
      },
      {
        key: "ship_pending",
        meta: { shipStatus: "pending" },
      },
      {
        key: "ship_deliver",
        meta: { shipStatus: "deliver" },
      },
      {
        key: "cancel",
        meta: { status: "cancel" },
      },
      {
        key: "complete",
        meta: { status: "complete" },
      },
    ];

    this.allfiltersOrder.forEach((e) => {
      e.name = this.getStatusName(e.key);
    });
  },
  watch: {
    filters: {
      handler() {
        this.outputFilters = this.filters;
      },
      deep: true,
    },
    outputFilters: {
      handler() {
        this.reset();
      },
      deep: true,
    },
    loading(v) {
      this.$emit("loading", v);
    },
  },
  computed: {
    nodata() {
      return this.endList === true && this.list.length === 0 && this.page == 1;
    },
    outputFiltersList() {
      return this.allfiltersOrder.filter((e) =>
        this.filtersKey.includes(e.key)
      );
    },
  },
  methods: {
    getTextKey: getTextKey,
    toDate(val) {
      return new Intl.DateTimeFormat("en-GB", {
        day: "numeric",
        year: "numeric",
        month: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: false,
      }).format(new Date(val));
    },
    async getData() {
      this.loading = true;
      const { status, body } = await GET(
        `/api/shops/${this.$myShop.id}/promotions?page=${this.page}&limit=5&${this.optionsUrl}`
      );

      this.loading = false;

      if (status !== 200) {
        this.error = true;
        return;
      }

      this.list.push(...body.data);

      if (this.page >= body.lastPage) {
        this.endList = true;
      } else {
        this.page++;
      }
    },
    getStatusName(key) {
      return statusKey[key][this.$shopType]?.n;
    },
    updatefilters(metaJoin) {
      this.outputFilters = {
        orderStatus: this.filters.orderStatus,
        ...metaJoin,
      };
    },
    reset() {
      console.log("beforeRouteEnter list");
      this.page = 1;
      this.endList = false;
      this.list = [];
      this.getData();
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.reset();
    });
  },
  mounted() {
    // this.getData();
  },
};
</script>
<style lang="scss" scoped>
// @import "./styles/tags.scss";

.shopName {
  &.sub-agent {
    color: #93c47d;
  }
  &.sub-direct {
    color: #93c47d;
  }
  &.agent {
    color: #38761d;
  }
  &.retail {
    color: #4a86e8;
  }
}

.orderList {
  overflow-y: auto;
  padding: 80px 0 0 0;
}

.card_wrap {
  color: rgb(88, 88, 88);
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.card {
  position: relative;
  cursor: pointer;
  width: 100%;
  max-width: 450px;
  margin: 6px 10px 6px 10px;
  font-size: 14px;
  line-height: 20px;
  overflow: hidden;
  background: #fff;
  padding: 10px;
  border-radius: 10px;
  // border: 1px solid #ddd;
  box-shadow: rgb(0 0 0 / 7%) 0px 3px 15px;
  // &:active::after {
  //   content: "";
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100%;
  //   pointer-events: none;
  //   background: rgba(255, 255, 255, 0.301);
  // }
  /* height: 280px; */
  span {
    font-weight: bold;
  }

  .sideFlex {
    display: flex;
    justify-content: space-between;
    .left {
      padding-right: 5px;
    }
  }

  .group {
    b {
      color: rgb(95, 95, 95);
      font-size: 13px;
    }
    margin: 5px 0;
  }

  .wrapFirst,
  .sumPriceWrap {
    padding: 5px 0 0 0;
    display: flex;
    justify-content: space-between;
  }
  .line {
    margin: 0 auto;
    padding: 3px 0;
    border-bottom: 1px solid rgb(226, 226, 226);
  }
  // &:last-child {
  //   margin-bottom: 40px;
  // }

  .bottomSection {
    display: flex;
    margin-top: 5px;
    justify-content: space-between;
    align-items: center;
  }

  .editBtn {
    font-size: 14px;
    border-radius: 6px;
    padding: 3px 9px;
    font-weight: bold;
    border: 2px solid rgb(218, 218, 218);
    color: rgb(145, 145, 145);
    &:active {
      background: rgba(0, 0, 0, 0.05);
    }
  }
}

.summaryPrice {
  color: var(--primary-color);
}

.des {
  margin-top: 6px;
}

.activeSection {
  margin-top: 6px;
  .active {
    color: #38761d;
  }

  .inActive {
    color: coral;
  }
}
</style>
